import React from 'react';
import './Gallery.css';
import Accordion from 'react-bootstrap/Accordion';
import GalleryGroupAccordion from '../../components/galleryGroup/GalleryGroupAccordion';
import BackgroundImage from '../../assets/gallery/background.jpg';

export default function Gallery() {

    function importAll(r) {
        let images = [];
        r.keys().map((item) => { images.push(r(item)); });
        return images;
    }

    const groups = [
        {
            title: "'Jungle Book Reimagined' by Akram Khan",
            subtitle: "2023-2025",
            images: importAll(require.context("../../assets/gallery/jungle-book/")),
        },
        {
            title: "'Outwitting The Devil' by Akram Khan",
            subtitle: "2020-2022",
            videos: ['pzoAH3CKIf8'],
            images: importAll(require.context("../../assets/gallery/owtd-akram-khan/")),
        },
        {
            title: "'Finality' by Rootlessroot",
            subtitle: "2021",
            images: importAll(require.context("../../assets/gallery/finality/")),
        },
        {
            title: "'Move' in Netflix by Akram Khan",
            subtitle: "2020",
            videos: ['qOZ62yiD1c8'],
            images: importAll(require.context("../../assets/gallery/netflix-bangladesh/")),
        },
        {
            title: "'Father: Vision of the floating World' by Akram Khan",
            subtitle: "2020",
            videos: ['vtprE-yXDPg'],
            images: importAll(require.context("../../assets/gallery/father-vision/")),
        },
        {
            title: "'From Freedom and Salt' by Vertexcomunicacio",
            subtitle: "2020",
            images: importAll(require.context("../../assets/gallery/from-freedom-and-salt/")),
        },
        {
            title: "'Exersice 27' by Christos Papadopoulos",
            subtitle: "2019",
            images: importAll(require.context("../../assets/gallery/exersice-27/")),
        },
        {
            title: "'Like One' by Elpida Skourou",
            subtitle: "2018",
            images: importAll(require.context("../../assets/gallery/like-one/")),
        },
        {
            title: "Intensives",
            images: importAll(require.context("../../assets/gallery/intensives/")),
        },
        {
            title: "Teacher of Musickinetics in kids",
            images: importAll(require.context("../../assets/gallery/music-kinetics-in-kids/")),
        },
    ];
    
    return (
        <div className='gallery-wrapper' style={{ backgroundImage: `url(${BackgroundImage})`, backgroundPosition: "left 45% top -10%"}}>
            <div className='container'>
                <div className='gallery-head-title'>
                    <h1><strong>PHOTOS</strong></h1>
                    <h1 style={{marginLeft: "40px"}}><strong>&</strong></h1>
                    <h1><strong>VIDEOS</strong></h1>
                </div>
                {/* <hr className='gallery-head-line'/> */}
                <Accordion defaultActiveKey="0" className='shadow'>
                    {groups.map((group, index)=> {
                        return (
                            <GalleryGroupAccordion index={index} title={group.title} subtitle={group.subtitle} images={group.images} videos={group.videos} />
                        );
                    })}
                </Accordion>
            </div>
        </div>

    );
}