import React from "react";
import './About.css';
import DisplayPage from "../../components/displayPage/DisplayPage";
import BackgroundImage from '../../assets/about/background.JPG';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';
import DisplayPageMobile from "../../components/displayPage/DisplayPageMobile";

export default function About() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const bio = {
        title: "Performer | Teacher | Collaborator",
        body: `Elpida was born and raised in Athens where she started ballet and contemporary dance at the tender age of four. 
        As a teenager, she simultaneously explored various genres of dance, including hip-hop, Latin, and tap dancing. 
        Driven by her passion for dance, Elpida pursued her formal education in the field and secured scholarship to study at the Greek National School of Dance, where she eventually graduated in 2018. 
        During her academic pursuit, Elpida had the honor of being selected by Antony Lachky to perform with the Hellenic Dance Company [Special Gala](https://ksot.gr/paragoges/tomorrow/)  in  Megaro Mousikis. 
        She also decided to go deeper into her passion for movement by studying Pilates and becoming a certified Teacher of Mat and Props. She now imparts her knowledge and skills to others through online classes. 
        After completing her studies, Elpida began her professional journey as a dancer and performed at the Athens and Epidaurus Festival, to perform [Exercise 27](https://ksot.gr/open-studio/) by Christos Papadopoulos. 
        In 2019, she decided to seek out opportunities abroad and she succeed to be a part of Akram Khan Company. 
        Her first project with the company was [Father: Vision of The Floating World](https://www.akramkhancompany.net/productions/father-vision-of-the-floating-world/) which was performed in Bangladesh in 2020 as part of the 100 years anniversary of the Father of the Nation, Sheik Mujib on Tuesday 17 March 2020 in Dhaka, Bangladesh. 
        She was also featured as part of Akram’s episode in the NETFLIX Series [MOVE](https://www.akramkhancompany.net/productions/move/). 
        While in Athens for a few months, Elpida served as an Assistant Choreographer and dancer for a RootlessRoot production in collaboration with the Greek National Opera for the [Finality](https://rootlessroot.com/performances/finality/) project, which was performed in 2021. 
        This project was a part of the grand celebrations of the 200th anniversary of the Greek Revolution and Independence. 
        From 2020 to 2022, Elpida was selected by Akram Khan Company to perform the role of Ching-Ying in [Outwitting the Devil](https://www.akramkhancompany.net/productions/outwitting-the-devil/) an extraordinary and powerful piece that she performed on a global tour for two years. 
        She had the opportunity to travel to numerous countries, including Abu Dhabi, Tel-Aviv, Romania, and almost all of Europe, and performed in various historical theaters such as the Herodes Atticus Theater in Athens. 
        In 2023, Elpida featured in a music video titled "INSIRGENTS" of Aditya Prakash choreographed by Akram Khan. Elpida currently features in Akram Khan's work "[Jungle Book Reimagined](https://www.akramkhancompany.net/productions/jungle-book-reimagined/)" a captivating production that has traversed across America , Australia, Asia and Europe , with plans to continue the tour until 2025.
        \nElpida also has attended many seminars such as : 
        \n- [2020] Fighting Monkey Intensive13  
        \n- [2020] Kalamata Dance Festival 
        \n- [2018-2019] Akram Khan Summer Intensive 
        \n- [2019] Impulstanz Festival  
        \n- [2017] Dance Days Chania  
        \n- [2016] Ecole de Danse Contemporaine de Montréal Summer School 
        \n- [2015] P.A.R.T.S Summer School
        `,
    }

    if (isMobile && isPortrait) {
        return (
            <DisplayPageMobile title={bio.title} backgroundImage={BackgroundImage}>
                {bio.subtitle ? <p><strong>{bio.subtitle}</strong></p> : null}
                {bio.body ? <ReactMarkdown className="markdown-mobile">{bio.body}</ReactMarkdown> : null}
            </DisplayPageMobile>
        );
    } else {
        return (
            <DisplayPage title={bio.title} backgroundImage={BackgroundImage} desktopSize={650} desktopSizeCheck={700}>
                {bio.subtitle ? <p><strong>{bio.subtitle}</strong></p> : null}
                {bio.body ? <ReactMarkdown className="markdown">{bio.body}</ReactMarkdown> : null}
            </DisplayPage>
        );
    }
}