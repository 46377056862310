import React from "react";
import { Link } from "react-router-dom";
import './Contact.css';
import { FaFacebookSquare, FaEnvelopeSquare, FaInstagramSquare, FaPhoneSquare } from 'react-icons/fa';
import BackgroundImage from '../../assets/contact/background.jpg';
import BackgroundImageSmall from '../../assets/contact/background-small.jpg';
import DisplayPage from "../../components/displayPage/DisplayPage";
import { useMediaQuery } from 'react-responsive';

export default function Contact() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const instagramDisplay = "instagram.com/elpidaskourou";
    const instagramUrl = "https://www.instagram.com/elpidaskourou";
    const facebookDisplay = "facebook.com/elpida.skourou";
    const facebookUrl = "https://www.facebook.com/elpida.skourou";
    const email = "elpidaskourou@gmail.com";

    return (
        <DisplayPage title="Contact" alwaysCenter 
        backgroundImage={isMobile && isPortrait ? BackgroundImageSmall : BackgroundImage} 
        backgroundPosition={isMobile && isPortrait ? "left 50% top 0": undefined}
        style={{alignItems: "center"}}>
            <Link className="contact-item" to={"mailto:" + email} target="_blank" rel="noopener noreferrer">
                <FaEnvelopeSquare className="contact-item-icon"/> {email}
            </Link>
            <Link className="contact-item" to={instagramUrl} target="_blank" rel="noopener noreferrer">
                <FaInstagramSquare className="contact-item-icon"/> {instagramDisplay}
            </Link>
            <Link className="contact-item" to={facebookUrl} target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare className="contact-item-icon"/> {facebookDisplay}
            </Link>
        </DisplayPage>
    );
}