import React, { useState, useCallback } from 'react';
import './GalleryGroup.css';
import ImageViewer from 'react-simple-image-viewer';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
import Accordion from 'react-bootstrap/Accordion';

export default function GalleryGroupAccordion(props) {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const youtubeHeight = (isMobile | isPortrait) ? '200' : '390';
    const youtubeWidth = (isMobile | isPortrait) ? '292' : '640';

    
    const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    };

    const opts = {
        height: youtubeHeight,
        width: youtubeWidth,
      };

    return (
        <Accordion.Item className='gallery-group-accordion-item' eventKey={props.index}>

            <Accordion.Header className='gallery-group-accordion-header'>
                {props.title
                    ? 
                    <div className='gallery-group-header'>
                        <div className='gallery-title'>{props.title}</div>
                        {props.subtitle ? <div className='gallery-subtitle'>{props.subtitle}</div> : null}
                    </div>
                    : null
                }
            </Accordion.Header>

            <Accordion.Body className='gallery-group-accordion-body'>

                <div className='gallery-group-video-content'>
                    {props.videos
                        ? props.videos.map(video => (<YouTube style={{ margin: '2px', border: "4px groove gray" }} videoId={video} opts={opts} onReady={(event) => event.target.pauseVideo()} />))
                        : null
                    }
                </div>

                <div className='gallery-group-content'>
                    {props.images.map((src, index) => (
                        <img
                        className='gallery-group-image'
                        src={ src }
                        onClick={ () => openImageViewer(index) }
                        width={300}
                        height="auto"
                        key={ index }
                        style={{ margin: '2px', border: "4px groove gray" }}
                        alt=""
                        />
                    ))}

                    {isViewerOpen && (
                        <ImageViewer
                        src={ props.images }
                        currentIndex={ currentImage }
                        disableScroll={ false }
                        closeOnClickOutside={ true }
                        onClose={ closeImageViewer }
                        />
                    )}

                </div>

            </Accordion.Body>
        </Accordion.Item>
    );
}