import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './MenuModal.css';
import Modal from 'react-modal';

export default function MenuModal(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const customStyles = {
        overlay: {
          backgroundColor: 'black',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '2',
        },
      };

    Modal.setAppElement('#root');

    function active(path) {
        if (location.pathname === path) {
            return "menu-modal-item menu-modal-item-active";
        }

        return "menu-modal-item";
    }

    const onMenuClickNavigate = (event, path) => {
        event.preventDefault();
        props.onClose();
        navigate(path);
    }

    return (
        <Modal
        shouldCloseOnOverlayClick={false}
        onRequestClose={props.onClose}
        isOpen={props.isOpen}
        style={customStyles}>
            <div className="menu-modal-header">ELPIDA SKOUROU</div>
            <ul className="menu-modal-list">
                <li key="li1" className={active("/")} onClick={(e) => onMenuClickNavigate(e, "/")}>Home</li>
                <li key="li2" className={active("/about")} onClick={(e) => onMenuClickNavigate(e, "/about")}>About</li>
                <li key="li3" className={active("/gallery")} onClick={(e) => onMenuClickNavigate(e, "/gallery")}>Gallery</li>
                <li key="li4" className={active("/online-sessions")} onClick={(e) => onMenuClickNavigate(e, "/online-sessions")}>Movement Adaptation</li>
                <li key="li5" className={active("/pilates-teaching")} onClick={(e) => onMenuClickNavigate(e, "/pilates-teaching")}>Pilates Teacher of Mat & Props</li>
                <li key="li6" className={active("/contact")} onClick={(e) => onMenuClickNavigate(e, "/contact")}>Contact</li>
            </ul>
        </Modal>
    );
}