import React from "react";
import './DisplayPageMobile.css';

export default function DisplayPageMobile(props) {

    return (
        <div className="display-page-mobile-wrapper">
            <img src={props.backgroundImage} alt="BackgroungImage" width="100%" height="auto"/>
            <h3 style={{marginTop: "15px"}}>{props.title}</h3>
            <hr/>
            <div className="display-page-children-mobile">
            {props.children}
            </div>              
        </div>
    );
}