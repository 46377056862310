import React from "react";
import './VerticalView.css';

export default function VerticalView(props) {

    return (
        <div className="vertical-wrapper">
            {props.title ? <div className="vertical-title">{props.title}</div> : null}
            {props.images.map((image) => {
                return (
                    <img src={image} className="carousel-image" />
                );
            })}
        </div>
    );
}