import React from "react";
import { useNavigate } from "react-router-dom";
import './OnlineSessions.css';
import BackgroundImage from '../../assets/online-sessions/background.jpg';
import DisplayPage from "../../components/displayPage/DisplayPage";
import { useMediaQuery } from 'react-responsive';
import DisplayPageMobile from "../../components/displayPage/DisplayPageMobile";

export default function OnlineSessions() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const navigate = useNavigate();

    const handleOnClick = (e) => {
        e.preventDefault();
        navigate("/contact");
    }

    const mainBody = () => {
        return (
            <div>
                <p><strong>I am currently offering private movement adaptation sessions that are suitable for all levels.</strong></p>
                <p>
                    Our body is designed for a wide range of small movements in different areas. The spine, for instance, has 24 vertebrae that can move into flexion, extension, rotation, side bending, and sliding. 
                    Twelve of these vertebrae connect to the ribs, which can articulate independently of the spine. Body movements are controlled by numerous muscles that pull in different directions. 
                    Some muscles connect vertebrae to their immediate neighbors, while others span almost the entire back. Other muscles connect the vertebrae to the ribs, pelvis, head, shoulder blade, arm, and femur.
                </p>
                <p>
                    The complexity of this anatomy provides us with infinite movement options. Why would we have so many options if we were not meant to use them? 
                </p>
                <p>
                    Our aim through these classes is to explore and create even more variations of movement, to teach our bodies to be adaptable in any situation. 
                    Each body is unique, so our priority is to learn to listen to it, explore it through improvisation, and understand it even better, enabling us to live in a healthier home. 
                </p>
                <p><strong>Please contact me directly for pricing and scheduling information.</strong></p>
                <p><strong>If you have any questions, feel free to email me, and we can work together to figure it out.</strong></p>
                <button onClick={(e) => handleOnClick(e)} className="btn btn-secondary contact-button">Contact</button>
            </div>
        );
    }

    if (isMobile && isPortrait) {
        return (
            <DisplayPageMobile title="Movement Adaptation | Online Classes" backgroundImage={BackgroundImage}>
                {mainBody()}
            </DisplayPageMobile>
        );
    } else {
        return (
            <DisplayPage title="Movement Adaptation | Online Classes" backgroundImage={BackgroundImage} desktopSize={750} desktopSizeCheck={800}>
                {mainBody()}
            </DisplayPage>
        );
    }
}