import React from "react";
import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useMediaQuery } from 'react-responsive';
import VerticalView from "../../components/verticalView/VerticalView";
import CarouselView from "../../components/carouselView/CarouselView";

export default function Home() {

    const title = 'ELPIDA SKOUROU';
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    function importAll(r) {
        let images = [];
        r.keys().map((item) => { images.push(r(item)); });
        return images;
    }

    const im = importAll(require.context("../../assets/carousel/"));

    return (isMobile || isPortrait
        ? <VerticalView title={title} images={im}/>
        : <CarouselView title={title} images={im}/>
        );
}