import React from "react";
import './CarouselView.css';
import { Carousel } from "react-responsive-carousel";

export default function CarouselView(props) {
    return (
        <div className="carousel-wrapper">
            {props.title ? <div className="carousel-title">{props.title}</div> : null}           
            <Carousel 
                stopOnHover={false} 
                autoPlay={true} 
                infiniteLoop={true} 
                interval={3000} 
                showThumbs={false} 
                showArrows={false} 
                showStatus={false} 
                showIndicators={true} 
                autoFocus={true}
            >
                {props.images.map((image) => {
                    return (
                        <div>
                            <img src={image} alt="Carousel" className="carousel-image" />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}