import React, { useState, useEffect } from "react";
import './DisplayPage.css';

export default function DisplayPage(props) {

    const calculateStyle = () => {

        if (props.desktopSize && props.desktopSizeCheck) {
            return { height: (window.innerHeight > props.desktopSizeCheck) 
                ? props.desktopSize + 'px' 
                : 65 * window.innerHeight / 100 + 'px'
                }
        } else {
            return null;
        }
    }

    const setCalculatedHeight = () => {
        setChildrenStyle(calculateStyle());
    }

    const [childrenStyle, setChildrenStyle] = useState(calculateStyle());
    
    useEffect(() => {
        window.addEventListener('resize', setCalculatedHeight);
        return () => {
          window.removeEventListener('resize', setCalculatedHeight);
        }
      }, [])

    const desktopColSize = props.desktopColSize ? props.desktopColSize : 5;
    const displayCenter = props.alwaysCenter ? "display-align-always-center" : "";

    const style = props.backgroundPosition 
    ? { backgroundImage: `url(${props.backgroundImage})`, backgroundPosition: props.backgroundPosition}
    : { backgroundImage: `url(${props.backgroundImage})`}

    return (
        <div className={"display-page-wrapper " + displayCenter} style={style}>
            <div className="container">
                <div className="row justify-content-end">
                    <div className={"col-12 col-md-"+ desktopColSize + " display-page-col shadow"}>
                        <h3>{props.title}</h3>
                        <hr/>
                        <div className="display-page-children" style={childrenStyle}>
                        {props.children}
                        </div>              
                    </div>
                </div>
            </div>
        </div>
    );
}