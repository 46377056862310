import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './PilatesTeaching.css';
import { useMediaQuery } from 'react-responsive';
import VerticalView from "../../components/verticalView/VerticalView";
import CarouselView from "../../components/carouselView/CarouselView";

export default function PilatesTeaching() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const calculateStyle = () => {
        return { height: (!isMobile && !isPortrait && window.innerHeight > 800) 
            ? 'auto' 
            : 75 * window.innerHeight / 100 + 'px'
            };
    }

    const setCalculatedHeight = () => {
        setChildrenStyle(calculateStyle());
    }

    const [childrenStyle, setChildrenStyle] = useState(calculateStyle());
    
    useEffect(() => {
        window.addEventListener('resize', setCalculatedHeight);
        return () => {
          window.removeEventListener('resize', setCalculatedHeight);
        }
      }, [])

    function importAll(r) {
        let images = [];
        r.keys().map((item) => { images.push(r(item)); });
        return images;
    }

    const im = importAll(require.context("../../assets/pilates-teaching/carousel/"));

    const navigate = useNavigate();

    const handleOnClick = (e) => {
        e.preventDefault();
        navigate("/contact");
    }

    const DesktopView = () => {
        return (
            <div>
                <MainBody/>
                <CarouselView images={im}/>
            </div>

        );
    }

    const MobileView = () => {
        return (
            <div>
                <MainBody/>
                <VerticalView images={im}/>
            </div>
        );
    }

    const MainBody = () => {
        return (
            <div className="col-10 col-md-4 shadow p-2 pilates-info-view" style={childrenStyle}>
                <h3><strong>Pilates Mat & Props Online Private Classes</strong></h3>
                <hr style={{borderTop: "5px solid black", opacity: 1}}/>
                <p><strong>I am currently offering private Pilates classes of mat and props that are suitable for all levels.</strong></p>
                <p>As a personalized private session, each class will be tailored to meet the individual's unique needs and goals.</p>
                <p>
                    These sessions will focus on virtual contact to ensure improvement. The goal of these private lessons is to improve, develop, and gain a better way of moving and living. 
                    We understand that it can be challenging to achieve a healthy and dynamic posture, which is why our classes explore different postural options to find what works best for each individual. 
                    Our body is our home, and it is essential to respect, listen to, and strengthen it.
                </p>
                <p><strong>Please contact me directly for pricing and scheduling information.</strong></p>
                <p><strong>If you have any questions, feel free to email me, and we can work together to figure it out.</strong></p>
                <button onClick={(e) => handleOnClick(e)} className="btn btn-secondary contact-button">Contact</button> 
            </div>
        );
    }

    return (
        isMobile || isPortrait ? <MobileView /> : <DesktopView/>
        );
}