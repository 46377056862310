import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Gallery from './pages/gallery/Gallery';
import PilatesTeaching from './pages/pilatesTeaching/PilatesTeaching';
import OnlineSessions from './pages/onlineSessions/OnlineSessions';
import Contact from './pages/contact/Contact';
import MenuModal from './components/menuModal/MenuModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";

const determineInsights = () => {

  if (window.location.hostname == "www.elpidaskourou.com") {
    // www
    return "G-N6VD8RZHDZ";
  } else {
    // root
    return "G-G2H5YP0NX4";
  }
}

ReactGA.initialize(determineInsights());

function App() {

  const [ modalState, setModalState ] = useState({
    open: false,
    icon: faBars,
  });

  function onModalToggle() {
    if (modalState.open) {
      setModalState({
        open: false,
        icon: faBars,
      })
    } else {
      setModalState({
        open: true,
        icon: faTimes,
      })
    }
  }

  function onModalClose() {
    setModalState({
      open: false,
      icon: faBars,
    })
  }

  return (
      <div className='app-wrapper'>
        <div className='menu-button-wrapper'>
          <FontAwesomeIcon onClick={onModalToggle} className="fa-lg menu-button" icon={modalState.icon} />
        </div>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/pilates-teaching" element={<PilatesTeaching/>} />
          <Route path="/online-sessions" element={<OnlineSessions/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
        <MenuModal isOpen={modalState.open} onClose={onModalClose} />
    </div>
  );
}

export default App;
